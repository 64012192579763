// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Logo from "./utils/Logo.bs.js";
import * as React from "react";
import * as Header from "./Header.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function HomeView(Props) {
  StateContext.use(undefined);
  ReactI18next.useTranslation();
  ReactNative.StyleSheet.create({
        aboutView: {
          left: 145.0,
          position: "absolute",
          top: 150.0
        },
        aboutButton: {
          height: 80.0,
          width: 80.0
        },
        searchView: {
          position: "absolute",
          right: 145.0,
          top: 150.0
        },
        searchButton: {
          height: 80.0,
          width: 80.0
        }
      });
  return React.createElement(ReactNative.View, {
              children: null
            }, React.createElement(Header.make, {}), React.createElement(Logo.make, {}), React.createElement(ReactNativePaper.Text, {
                  children: "Nothing here"
                }));
}

var make = HomeView;

export {
  make ,
}
/* Logo Not a pure module */
