// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Core from "../Core.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Election from "../model/Election.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";

function ElectionBooth$Choice(Props) {
  var name = Props.name;
  var selected = Props.selected;
  var onSelect = Props.onSelect;
  var iconName = selected ? "radiobox-marked" : "radiobox-blank";
  return React.createElement(ReactNativePaper.List.Item, {
              onPress: (function (param) {
                  Curry._1(onSelect, undefined);
                }),
              title: name,
              style: {
                padding: 20.0,
                paddingLeft: 40.0
              },
              left: (function (param) {
                  return React.createElement(ReactNativePaper.List.Icon, {
                              icon: iconName
                            });
                })
            });
}

var Choice = {
  make: ElectionBooth$Choice
};

function ElectionBooth$BoothAfterVote(Props) {
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(ReactNativePaper.Text, {
                  style: S.flatten([
                        S.title,
                        {
                          margin: 30.0
                        }
                      ]),
                  children: "Merci pour votre vote"
                }), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: electionId,
                                tl: /* [] */0
                              }
                            }
                          });
                    }),
                  title: "Retour à l'élection"
                }));
}

var BoothAfterVote = {
  make: ElectionBooth$BoothAfterVote
};

function ElectionBooth$BoothClassic(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var account = Props.account;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = ReactI18next.useTranslation();
  var match$2 = React.useState(function () {
        
      });
  var setChoice = match$2[1];
  var choice = match$2[0];
  var question = Election.description(election);
  var question$1 = question === "" ? match$1.t("election.new.question") : question;
  return React.createElement(React.Fragment, undefined, React.createElement(ReactNative.View, {
                  style: S.questionBox,
                  children: null
                }, React.createElement(S.Section.make, {
                      title: question$1
                    }), Belt_Array.mapWithIndex(Election.choices(election), (function (i, choiceName) {
                        var selected = Caml_obj.equal(choice, i);
                        return React.createElement(ElectionBooth$Choice, {
                                    name: choiceName,
                                    selected: selected,
                                    onSelect: (function (param) {
                                        Curry._1(setChoice, (function (param) {
                                                return i;
                                              }));
                                      }),
                                    key: String(i)
                                  });
                      }))), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      var nbChoices = Election.choices(election).length;
                      Core.Ballot.vote(electionId, account, choice, nbChoices, state, dispatch);
                    }),
                  title: "Voter"
                }));
}

var BoothClassic = {
  make: ElectionBooth$BoothClassic
};

function ElectionBooth$BoothMajorityJudgement(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var account = Props.account;
  var candidates = Election.questions(election);
  var nCandidates = candidates.length;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = ReactI18next.useTranslation();
  var match$2 = React.useState(function () {
        return Belt_Array.make(nCandidates, undefined);
      });
  var setChoices = match$2[1];
  var choices = match$2[0];
  var question = Election.description(election);
  var question$1 = question === "" ? match$1.t("election.new.question") : question;
  var submitStyle = Belt_Array.every(choices, Belt_Option.isSome) ? ({}) : ({
        backgroundColor: "gray"
      });
  return React.createElement(React.Fragment, undefined, React.createElement(S.Section.make, {
                  title: question$1
                }), Belt_Array.mapWithIndex(candidates, (function (i, candidateName) {
                    return React.createElement(ReactNative.View, {
                                style: S.questionBox,
                                children: null
                              }, React.createElement(S.Section.make, {
                                    title: candidateName
                                  }), Belt_Array.mapWithIndex([
                                    "Excellent",
                                    "Very good",
                                    "Good",
                                    "Passable",
                                    "Inadequate",
                                    "Mediocre"
                                  ], (function (r, choiceName) {
                                      var selected = Caml_obj.equal(Belt_Array.getExn(choices, i), r);
                                      return React.createElement(ElectionBooth$Choice, {
                                                  name: choiceName,
                                                  selected: selected,
                                                  onSelect: (function (param) {
                                                      Curry._1(setChoices, (function (param) {
                                                              return Belt_Array.mapWithIndex(choices, (function (idx, e) {
                                                                            if (idx === i) {
                                                                              return r;
                                                                            } else {
                                                                              return e;
                                                                            }
                                                                          }));
                                                            }));
                                                    }),
                                                  key: String(i)
                                                });
                                    })));
                  })), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      if (Belt_Array.every(choices, Belt_Option.isSome)) {
                        var choices$1 = Belt_Array.map(choices, (function (o) {
                                return Belt_Option.getWithDefault(o, 0);
                              }));
                        return Core.Ballot.voteMJ(electionId, account, choices$1, state, dispatch);
                      }
                      console.log("You need to make a choice for every candidate");
                    }),
                  title: "Voter",
                  style: submitStyle
                }));
}

var BoothMajorityJudgement = {
  make: ElectionBooth$BoothMajorityJudgement
};

function ElectionBooth(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var state = match[0];
  var accounts = Belt_Array.keep(state.accounts, (function (account) {
          return Belt_Option.isSome(Belt_Array.getBy(election.voterIds, (function (userId) {
                            return userId === account.userId;
                          })));
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election
                }), accounts.length === 0 ? React.createElement(ReactNativePaper.Text, {
                    style: S.flatten([
                          S.title,
                          {
                            margin: 30.0
                          }
                        ]),
                    children: "Vous n'avez pas de clés de vote"
                  }) : Belt_Array.map(accounts, (function (account) {
                      var ballot = Belt_Array.getBy(state.ballots, (function (ballot) {
                              if (ballot.electionId === electionId) {
                                return ballot.voterId === account.userId;
                              } else {
                                return false;
                              }
                            }));
                      if (ballot !== undefined) {
                        return React.createElement(ElectionBooth$BoothAfterVote, {
                                    electionId: electionId
                                  });
                      } else {
                        return React.createElement(ElectionBooth$BoothMajorityJudgement, {
                                    election: election,
                                    electionId: electionId,
                                    account: account
                                  });
                      }
                    })));
}

var make = ElectionBooth;

export {
  Choice ,
  BoothAfterVote ,
  BoothClassic ,
  BoothMajorityJudgement ,
  make ,
}
/* S Not a pure module */
