// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as $$URL from "../helpers/URL.bs.js";
import * as React from "react";
import * as Belenios from "../helpers/Belenios.bs.js";
import * as Election from "../model/Election.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CopyButton from "./utils/CopyButton.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativeSvg from "react-native-svg";
import * as Color$ReactNative from "rescript-react-native/src/apis/Color.bs.js";
import * as ReactNativePaper from "react-native-paper";

function ElectionResult(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var electionUrl = "" + $$URL.base_url + "/elections/" + electionId + "/result";
  var nbVotes = Belt_Array.keep(match[0].ballots, (function (ballot) {
          return ballot.electionId === electionId;
        })).length;
  var result = election.result;
  var data = result !== undefined ? Belenios.Election.scores(result) : [];
  var colors = Belt_Array.map([
        [
          229,
          193,
          189
        ],
        [
          123,
          158,
          135
        ],
        [
          94,
          116,
          127
        ],
        [
          210,
          208,
          186
        ]
      ], (function (param) {
          return Color$ReactNative.rgb(param[0], param[1], param[2]);
        }));
  var choices = Election.choices(election);
  var question = Election.description(election);
  var tmp = question === "" ? React.createElement(React.Fragment, undefined) : React.createElement(S.Section.make, {
          title: question
        });
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election,
                  section: "result"
                }), React.createElement(ReactNative.View, {
                  style: S.questionBox,
                  children: null
                }, tmp, React.createElement(S.Section.make, {
                      title: "" + String(nbVotes) + " votants"
                    }), Belt_Array.mapWithIndex(Election.questions(election), (function (n, candidate) {
                        var maxValue = Belt_Array.reduce(Belt_Array.getExn(data, n), 0, (function (v1, v2) {
                                if (v1 > v2) {
                                  return v1;
                                } else {
                                  return v2;
                                }
                              }));
                        return React.createElement(S.Row.make, {
                                    children: null
                                  }, React.createElement(S.Section.make, {
                                        title: candidate
                                      }), Belt_Array.mapWithIndex(Belt_Array.getExn(data, n), (function (i, value) {
                                          var color = Belt_Option.getWithDefault(Belt_Array.get(colors, i), Color$ReactNative.rgb(128, 128, 128));
                                          var ratio = value / maxValue;
                                          var ratio$1 = Number.isNaN(ratio) ? 0.0 : ratio;
                                          var ratio$2 = Math.max(ratio$1, 0.05);
                                          var choiceName = Belt_Option.getExn(Belt_Array.get(choices, i));
                                          return React.createElement(S.Col.make, {
                                                      children: null,
                                                      key: String(i)
                                                    }, React.createElement(ReactNativeSvg.Svg, {
                                                          style: {
                                                            alignSelf: "center",
                                                            height: 100.0 * ratio$2,
                                                            marginBottom: 10.0,
                                                            marginTop: 50.0,
                                                            paddingTop: 100.0 * (1 - ratio$2),
                                                            width: 20.0
                                                          },
                                                          children: React.createElement(ReactNativeSvg.Rect, {
                                                                width: 20.0,
                                                                height: 100.0 * ratio$2,
                                                                fill: color
                                                              })
                                                        }), React.createElement(ReactNativePaper.Text, {
                                                          style: {
                                                            color: color,
                                                            alignSelf: "center"
                                                          },
                                                          children: "" + choiceName + " (" + String(value) + ")"
                                                        }));
                                        })));
                      }))), React.createElement(ReactNative.View, {
                  style: {
                    margin: 30.0
                  },
                  children: React.createElement(S.TextInput.make, {
                        value: electionUrl,
                        onChangeText: (function (param) {
                            
                          })
                      })
                }), React.createElement(CopyButton.make, {
                  text: electionUrl
                }));
}

var make = ElectionResult;

export {
  make ,
}
/* S Not a pure module */
