// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belenios from "../helpers/Belenios.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function make(election, electionId, voterId, selections) {
  var trustees = election.trustees;
  var params = JSON.parse(election.params);
  var match = Belenios.Credentials.create(params.uuid, 1);
  var pubcred = Belt_Array.getExn(match[0], 0);
  var privcred = Belt_Array.getExn(match[1], 0);
  var ciphertext = Belenios.Election.vote(params)(privcred, selections, trustees);
  return {
          electionId: electionId,
          voterId: voterId,
          ciphertext: ciphertext,
          pubcred: pubcred
        };
}

export {
  make ,
}
/* Belenios Not a pure module */
