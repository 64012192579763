// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "./state/State.bs.js";
import * as Ballot from "./model/Ballot.bs.js";
import * as Event_ from "./model/Event_.bs.js";
import * as Account from "./model/Account.bs.js";
import * as Trustee from "./model/Trustee.bs.js";
import * as Belenios from "./helpers/Belenios.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function create(name, desc, choices, state, dispatch) {
  var account = Belt_Array.get(state.accounts, 0);
  var admin;
  if (account !== undefined) {
    admin = account;
  } else {
    var account$1 = Account.make(undefined);
    Curry._1(dispatch, {
          TAG: /* Account_Add */1,
          _0: account$1
        });
    admin = account$1;
  }
  var trustee = Trustee.make(undefined);
  var trustees = trustee.trustees;
  Curry._1(dispatch, {
        TAG: /* Trustee_Add */4,
        _0: trustee
      });
  var params = Belenios.Election._create(name, desc, choices, trustees);
  var election_adminIds = [admin.userId];
  var election_voterIds = [];
  var election = {
    electionId: undefined,
    adminIds: election_adminIds,
    voterIds: election_voterIds,
    params: params,
    trustees: trustees,
    pda: undefined,
    pdb: undefined,
    result: undefined
  };
  var $$event = Event_.ElectionInit.create(election, admin);
  Curry._1(dispatch, {
        TAG: /* Event_Add_With_Broadcast */3,
        _0: $$event
      });
  Curry._1(dispatch, {
        TAG: /* Navigate */0,
        _0: {
          hd: "elections",
          tl: {
            hd: $$event.cid,
            tl: /* [] */0
          }
        }
      });
}

function createMJ(name, desc, candidates, state, dispatch) {
  var account = Belt_Array.get(state.accounts, 0);
  var admin;
  if (account !== undefined) {
    admin = account;
  } else {
    var account$1 = Account.make(undefined);
    Curry._1(dispatch, {
          TAG: /* Account_Add */1,
          _0: account$1
        });
    admin = account$1;
  }
  var trustee = Trustee.make(undefined);
  var trustees = trustee.trustees;
  Curry._1(dispatch, {
        TAG: /* Trustee_Add */4,
        _0: trustee
      });
  var params = Belenios.Election._createMJ(name, desc, candidates, trustees);
  var election_adminIds = [admin.userId];
  var election_voterIds = [];
  var election = {
    electionId: undefined,
    adminIds: election_adminIds,
    voterIds: election_voterIds,
    params: params,
    trustees: trustees,
    pda: undefined,
    pdb: undefined,
    result: undefined
  };
  var $$event = Event_.ElectionInit.create(election, admin);
  Curry._1(dispatch, {
        TAG: /* Event_Add_With_Broadcast */3,
        _0: $$event
      });
  Curry._1(dispatch, {
        TAG: /* Navigate */0,
        _0: {
          hd: "elections",
          tl: {
            hd: $$event.cid,
            tl: /* [] */0
          }
        }
      });
}

function tally(electionId, state, dispatch) {
  var election = Belt_MapString.getExn(state.elections, electionId);
  var params = JSON.parse(election.params);
  var trustees = election.trustees;
  var trustee = Belt_Array.getBy(state.trustees, (function (trustee) {
          return Belenios.Trustees.pubkey(trustees) === Belenios.Trustees.pubkey(trustee.trustees);
        }));
  var privkey = Belt_Option.getExn(trustee).privkey;
  var ballots = Belt_Array.keep(state.ballots, (function (ballot) {
          return ballot.electionId === electionId;
        }));
  var ciphertexts = Belt_Array.map(Belt_Array.map(ballots, (function (ballot) {
              return ballot.ciphertext;
            })), (function (prim) {
          return prim;
        }));
  var pubcreds = Belt_Array.map(ballots, (function (ballot) {
          return ballot.pubcred;
        }));
  var match = Belenios.Election.decrypt(params)(ciphertexts, trustees, pubcreds, privkey);
  var b = match[1];
  var a = match[0];
  var result = Belenios.Election.result(params)(ciphertexts, trustees, pubcreds, a, b);
  var admin = State.getElectionAdminExn(state, election);
  var ev = Event_.ElectionTally.create({
        electionId: electionId,
        pda: a,
        pdb: b,
        result: result
      }, admin);
  return Curry._1(dispatch, {
              TAG: /* Event_Add_With_Broadcast */3,
              _0: ev
            });
}

var Election = {
  create: create,
  createMJ: createMJ,
  tally: tally
};

function vote(electionId, voter, choice, nbChoices, state, dispatch) {
  var election = Belt_MapString.getExn(state.elections, electionId);
  var selections = [Belt_Array.mapWithIndex(Belt_Array.make(nbChoices, 0), (function (i, _value) {
            if (Caml_obj.equal(choice, i)) {
              return 1;
            } else {
              return 0;
            }
          }))];
  var ballot = Ballot.make(election, electionId, voter.userId, selections);
  var ev = Event_.ElectionBallot.create(ballot, voter);
  return Curry._1(dispatch, {
              TAG: /* Event_Add_With_Broadcast */3,
              _0: ev
            });
}

function voteMJ(electionId, voter, choices, state, dispatch) {
  var election = Belt_MapString.getExn(state.elections, electionId);
  var selections = Belt_Array.map(choices, (function (choice) {
          return Belt_Array.mapWithIndex(Belt_Array.make(6, 0), (function (i, _value) {
                        if (choice === i) {
                          return 1;
                        } else {
                          return 0;
                        }
                      }));
        }));
  var ballot = Ballot.make(election, electionId, voter.userId, selections);
  var ev = Event_.ElectionBallot.create(ballot, voter);
  return Curry._1(dispatch, {
              TAG: /* Event_Add_With_Broadcast */3,
              _0: ev
            });
}

var Ballot$1 = {
  vote: vote,
  voteMJ: voteMJ
};

export {
  Election ,
  Ballot$1 as Ballot,
}
/* Ballot Not a pure module */
