// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Core from "../Core.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Header from "./Header.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as ElectionNewChoiceList from "./ElectionNewChoiceList.bs.js";

function ElectionNew(Props) {
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  var match$2 = React.useState(function () {
        return "";
      });
  var setName = match$2[1];
  var name = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setDesc = match$3[1];
  var desc = match$3[0];
  var match$4 = React.useState(function () {
        return [
                "",
                ""
              ];
      });
  var choices = match$4[0];
  var electionCreate = function (param) {
    Core.Election.createMJ(name, desc, choices, state, dispatch);
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Header.make, {
                  title: t("election.new.header")
                }), React.createElement(S.Section.make, {
                  title: t("election.new.title")
                }), React.createElement(S.TextInput.make, {
                  testID: "election-title",
                  value: name,
                  onChangeText: (function (text) {
                      Curry._1(setName, (function (param) {
                              return text;
                            }));
                    })
                }), React.createElement(S.Section.make, {
                  title: t("election.new.question")
                }), React.createElement(S.TextInput.make, {
                  testID: "election-desc",
                  value: desc,
                  onChangeText: (function (text) {
                      Curry._1(setDesc, (function (param) {
                              return text;
                            }));
                    })
                }), React.createElement(ElectionNewChoiceList.make, {
                  choices: choices,
                  setChoices: match$4[1]
                }), React.createElement(S.Button.make, {
                  onPress: electionCreate,
                  title: t("election.new.next")
                }));
}

var make = ElectionNew;

export {
  make ,
}
/* S Not a pure module */
